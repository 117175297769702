@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: "Montserrat-Black";
  src: url("./assets/fonts/Montserrat-Black.ttf");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/fonts/Montserrat-Regular.ttf");
}
body {
  --dana-color: #f82121;

  margin: 0 auto;

  font-family: "Montserrat-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat-Regular", sans-serif;
}
